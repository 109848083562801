import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/background-image.svg"
import thumbnailEvent from "../../static/images/products.svg"
import peerToPeer from "../../static/images/gift-delivery.svg"
import thumbnailDelivery from "../../static/images/transport-travel.svg"
import { Link } from "gatsby"
import {
  BUSINESS_SIGN_UP_FORM_URL,
  DRIVER_SIGN_UP_FORM_URL,
  t,
} from "../utils/constant"
import { withTrans } from "../i18n/withTrans"

const IndexPage = ({ t: trans, intl }) => (
  <Layout>
    <SEO title={t({ intl: trans, id: "seoTitleIndex" })} />

    <div className={"page-header home"}>
      <div className="top-content">
        <h1>{t({ intl: trans, id: "headerTitleIndex" })}</h1>

        <p>{t({ intl: trans, id: "headerSubtitleIndex" })}</p>
        <div className={"button main-bottom"}>
          <a href={BUSINESS_SIGN_UP_FORM_URL} target={"_blank"}>
            {t({ intl: trans, id: "labelGetStarted" })}
          </a>
          <Link to="/business" title={"business"} className="learn-more">
            {t({ intl: trans, id: "labelLearnMore" })}
          </Link>
        </div>
      </div>
      <div className="top-image">
        <img alt={"Dashboard"} src={featureImage} />
      </div>
    </div>
    <div className={"call-to-action"}>
      <div className={"container"}>
        <div className={"call-to-action__content"}>
          <h2>{t({ intl: trans, id: "headerEarningMore" })}</h2>
          <p>{t({ intl: trans, id: "textEarningMore" })}</p>
        </div>
        <div className={"button"}>
          <a href={DRIVER_SIGN_UP_FORM_URL} target={"_blank"}>
            {t({ intl: trans, id: "linkCourier" })}
          </a>
        </div>
      </div>
    </div>
    <div className={"container"}>
      <div className={"features"}>
        <div className={"feature__item"}>
          <div className={"column"}>
            <div className={"col-12 feature__center"}>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerSendItAnywhere" })}</h2>
              </div>
            </div>
            <div className={"col-12"}>
              <div className={"feature__content offerings"}>
                <p>{t({ intl: trans, id: "headerOffering1" })}</p>
                <p>{t({ intl: trans, id: "headerOffering2" })}</p>
              </div>
            </div>
            <div className={"col-12 first"}>
              <div className={"thumbnail offerings"}>
                <img alt={"Event"} src={thumbnailEvent} />
              </div>
            </div>
          </div>
        </div>
        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Peer to peer"} src={peerToPeer} />
              </div>
            </div>

            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerGetProductToCustomer" })}</h2>
                <p>{t({ intl: trans, id: "textGetProductToCustomer" })}</p>
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerTrustedService" })}</h2>
                <p>{t({ intl: trans, id: "textTrustedService" })}</p>
              </div>
            </div>

            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Board"} src={thumbnailDelivery} />
              </div>
            </div>
          </div>
        </div>
        <div className={"feature__item"}>
          <div className={"row"}>
            <div className="col-12">
              <div
                style={{ textAlign: "center" }}
                className={"feature__content"}
              >
                <h2>{t({ intl: trans, id: "headerSuccessStory" })}</h2>
              </div>
              <div className={"feature__content"}>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    lineHeight: 1.725,
                    fontSize: "1.1rem",
                    letterSpacing: "0.025rem",
                  }}
                >
                  {t({ intl: trans, id: "textSuccessStory" })}
                </p>
                <p
                  style={{ textAlign: "right", marginTop: 15, marginRight: 20 }}
                >
                  <span style={{ fontWeight: 600 }}>
                    {t({ intl: trans, id: "nameAslihanOguz" })}
                  </span>{" "}
                  <br />
                  {t({ intl: trans, id: "roleSuccessStudioOguz" })}
                </p>
              </div>
              <div
                style={{
                  borderTop: "1px solid gray",
                  marginTop: "3rem",
                  paddingTop: "2rem",
                }}
                className={"feature__content"}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    lineHeight: 1.725,
                    fontSize: "1.1rem",
                    letterSpacing: "0.025rem",
                  }}
                >
                  {t({ intl: trans, id: "textWeAreGroupSuccessStory" })}
                </p>
                <p
                  style={{ textAlign: "left", marginTop: 15, marginRight: 20 }}
                >
                  <span style={{ fontWeight: 600 }}>
                    {t({ intl: trans, id: "nameVeraWeAreGroup" })}
                  </span>{" "}
                  <br />
                  {t({ intl: trans, id: "roleSuccessVeraWeAreGroup" })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={"call-to-action cta-bottom"}>
      <div className={"container"}>
        <div className={"call-to-action__content"}>
          <h2>{t({ intl: trans, id: "headerBecomePartner" })}</h2>
          <p>{t({ intl: trans, id: "textBecomePartner" })}</p>
        </div>

        <div className={"button"}>
          <a href={BUSINESS_SIGN_UP_FORM_URL} target={"_blank"}>
            {t({ intl: trans, id: "textSignUp" })}
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default withTrans(IndexPage)
